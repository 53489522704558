<template src="./ProduceRSA.html"></template>
<style lang="scss" src="@/components/pages/PWAssetCreate/ProduceRSAComponent/ProduceRSA.scss"></style>
<script lang="ts">
import {
  Account,
  AD_RELEVANCE,
  AD_STRENGTH,
  AdGroup,
  ASSET_PINNED_FILED,
  ASSET_TYPE,
  BannedAsset,
  CandidateAsset,
  ChampionRSAAd,
  EXPECTED_CTR,
  KeywordImpConv,
  NG_TYPE,
  NGWord,
  PRE_COMBINED_RSA_FILTER_LOGIC,
  PRE_COMBINED_RSA_GENERATION_STRATEGY,
  PreCombinedAds,
  PreCombinedCandidateAd,
  RSAAsset,
  RSAAssetImp,
  RSAProduct,
} from '@/stores/model/domain'
import {powerwordState} from '@/stores/state/powerword/powerword'
import {auth} from '@/stores/authorization-state'
import Multiselect from '@vueform/multiselect'
import {campaignAdgroupRepository} from '@/stores/repository/campaign_adgroup'
import {computed, defineComponent, ref} from 'vue'
import {rsaAdRepository} from '@/stores/repository/rsa_ad'
import {textLength} from '@/utils/validator'
import useTdUtils from '@/utils/td-utils'
import {Format} from '@/utils/index'
import {
  convertResponseToModel as KeywordConvertToModel,
  keywordRepository,
  KeywordsWithImpConvResponse,
} from '@/stores/repository/keyword'
import {ngWordRepository} from '@/stores/repository/ng_word'
import ProduceRSAHistoryModal
  from '@/components/pages/PWAssetCreate/Modals/ProduceRSAHistoryModal/ProduceRSAHistoryModal.vue'
import {preCombinedAdRepository} from '@/stores/repository/pre_combined_ad';
import LLMAssetGeneratorComponent from './LLMAssetGeneratorComponent/LLMAssetGenerator.vue'
import {checkNGWord, checkNGWordForRSAAsset} from '@/utils/rsa/ngword_check';
import BannedAssetModal from '@/components/pages/PWAssetCreate/Modals/BannedAssetModal/BannedAssetModal.vue';
import {bannedAssetRepository} from '@/stores/repository/banned_asset';
import {isBannedAsset} from '@/utils/rsa/banned_asset_check';

const ProduceRSAComponent = defineComponent({
  components: {
    LLMAssetGeneratorComponent,
    ProduceRSAHistoryModal,
    BannedAssetModal,
    Multiselect,
  },
  setup() {
    const {
      scoreFormat,
    } = useTdUtils()

    const TITLE_UPPER_LIMIT = 15
    const DESCRIPTION_UPPER_LIMIT = 4
    const SELECTION_COUNT = 6

    // Sub Component
    const llmAssetGeneratorComponent = ref()
    // Modal
    const produceRSAHistoryModal = ref()
    const bannedAssetModal = ref()
    // TODO:// caxxxまとめてCandidateAdみたいな型つくるか？
    const caPreCombinedRSAId = ref <string | null>(null)
    const caTitles = ref < CandidateAsset[] > ([])
    const caDescriptions = ref < CandidateAsset[] > ([])
    const championRSAAd = ref < ChampionRSAAd | null > (null)
    const caScore = ref < number | null > (null)
    const rsaAdStrength = ref < AD_STRENGTH | null > (null)
    const rsaAdScore = ref < number | null > (null)
    const errorMessage = ref < string | null > (null)
    const caAdStrength = ref < AD_STRENGTH | null > (null)
    const highKeywordsImp = ref < KeywordImpConv[] > ([])
    const lowKeywordsImp = ref < KeywordImpConv[] > ([])
    const isLowKeywordsVisible = ref < boolean > (true)
    const isNGWordsVisible = ref < boolean > (true)
    const ngWords = ref < NGWord[] > ([])
    const preCombinedCandidateAds = ref <PreCombinedCandidateAd[]>([])
    const selectedPriority = ref< number | null >(null)
    const bannedAssets = ref < BannedAsset[] > ([])
    const isShowBanButton = ref < boolean >(false)

    // 子（LLMAssetGenerator）で更新された値を親で保持
    const llmCandidateAssets = ref < CandidateAsset[] > ([])

    const pinnedFieldHeadlines = [
      null,
      ASSET_PINNED_FILED.HEADLINE_1,
      ASSET_PINNED_FILED.HEADLINE_2,
      ASSET_PINNED_FILED.HEADLINE_3,
    ]

    const pinnedFieldDescriptions = [
      null,
      ASSET_PINNED_FILED.DESCRIPTION_1,
      ASSET_PINNED_FILED.DESCRIPTION_2,
    ]

    // Client
    const selectedClient = computed(() => {
      return powerwordState.selectedClientCompany
    })

    const textFullLength = (str: string | null) => {
      return textLength(str)
    }

    const adStrengthFormat = (adStrength: AD_STRENGTH | null) => {
      return Format.adStrengthAsJPN(adStrength)
    }

    const pinnedFieldFormat = (pinnedField: ASSET_PINNED_FILED | null) => {
      return Format.pinnedFieldNum(pinnedField)
    }

    const ngCheckTypeFormat = (ngType: NG_TYPE) => {
      return Format.ngCheckType(ngType)
    }

    const ngCheckTypeFormatForRSA = (ngType: NG_TYPE) => {
      return Format.ngCheckTypeForRSA(ngType)
    }

    const resetInputs = () => {
      selectedPriority.value = null
      caPreCombinedRSAId.value = null
      caTitles.value = []
      caDescriptions.value = []
      championRSAAd.value = null
      errorMessage.value = null
      caScore.value = null
      rsaAdScore.value = null
      rsaAdStrength.value = null
      caAdStrength.value = null
      highKeywordsImp.value = []
      lowKeywordsImp.value = []
      ngWords.value = []
      preCombinedCandidateAds.value = []
      bannedAssets.value = []
    }

    // Account
    const selectedAccount = computed(() => {
      return powerwordState.selectedAccount
    })

    const accounts = computed(() => {
      return powerwordState.accounts
    })

    // AdGroup
    const adGroups = computed(() => {
      return powerwordState.adGroups
    })

    const selectedAdGroup = computed(() => {
      return powerwordState.selectedAdGroup
    })

    // Multiselect用
    const selectAccountOptions = computed(() => {
      return powerwordState.accountOptions
    })
    const selectedAccountValue = ref<Account | null>(null)
    const onSelectAccount = (account: Account) => {
      resetInputs()
      powerwordState.setSelectedAccount(account)
      powerwordState.setSelectedAdGroup(null)
      powerwordState.setLoadingOn()
      campaignAdgroupRepository.fetchCampaignAdGroups({
        token: auth.token,
        account,
      }).then((v) => {
        powerwordState.setAdGroups(v)
      }).then(() => {
        powerwordState.setLoadingOff()
      })
    }

    const selectAdGroupOptions = computed(() => {
      return powerwordState.adGroupOptions
    })
    const selectedAdGroupValue = ref<AdGroup | null>(null)
    const onSelectAdGroup = (adgroup: AdGroup | null) => {
      resetInputs()
      const clientCompany = powerwordState.selectedClientCompany
      const account = powerwordState.selectedAccount
      if (!clientCompany || !account || !adgroup) { return null }
      powerwordState.setSelectedAdGroup(adgroup)
      powerwordState.setLoadingOn()

      const kip = keywordRepository.fetchKeywordsWithImpConv({
        token: auth.token,
        accountId: adgroup.account_id,
        mediaId: adgroup.media_id,
        campaignId: adgroup.campaign_id,
        adgroupId: adgroup.adgroup_id,
      }).then((kr: KeywordsWithImpConvResponse) => {
        const keywordsImp = kr.results.map((k) => {
          return Object.assign(KeywordConvertToModel(k), {
            imp: k.imp,
            conv: k.conv,
            expected_ctr: k.expected_ctr,
            ad_relevance: k.ad_relevance,
          })
        }) as KeywordImpConv[]
        let countImp = 0
        const highImpRange = kr.total_imp * 0.8

        keywordsImp.forEach((k: KeywordImpConv) => {
          if (countImp < highImpRange) {
            highKeywordsImp.value.push(k)
          } else {
            lowKeywordsImp.value.push(k)
          }
          countImp += k.imp
        })
      })

      // BannedAsset取得
      const bap = bannedAssetRepository.fetchBannedAssets({
        token: auth.token,
        cmClientCompanyId: clientCompany.cm_client_company_id,
      }).then((bas: BannedAsset[]) => {
        bannedAssets.value = bas
      })

      // NGWord取得（BlackList考慮）
      const ngwp = ngWordRepository.fetchNGWordsIncludeBL({
        token: auth.token,
        cmClientCompanyId: clientCompany.cm_client_company_id,
        mediaId: adgroup.media_id,
        accountId: adgroup.account_id,
        campaignId: adgroup.campaign_id,
        adgroupId: adgroup.adgroup_id,
      }).then((ngws: NGWord[]) => {
        ngWords.value = ngws
      })

      // ngword/banned_assetsを取得、champion_rsa_ads/candidate_assetsを取得した後、caに対してbanned_assetsを反映させる
      const bangwp = Promise.all([bap, ngwp]).then(() => {
        // ngwを取得してからpre-combined or championRSAAdを試行する
        return preCombinedAdRepository.fetchAssets({
          token: auth.token,
          media_id: account.media_id,
          account_id: account.account_id,
          campaign_id: adgroup.campaign_id,
          adgroup_id: adgroup.adgroup_id,
          selection_count: SELECTION_COUNT,
        }).then((result: PreCombinedAds) => {
          // 事前RSAAdAsset、事前組み合わせCandidateAdAssetsがあればそちらを使う
          if (!!result.champion_ad && result.candidate_ads.length > 0) {
            championRSAAd.value = result.champion_ad
            preCombinedCandidateAds.value = result.candidate_ads
            // 事前組み合わせCandidateAssetを画面左に反映
            switchPreCombinedAdAsset(1, false)
            powerwordState.setLoadingOff()
            return
          }
          // 実績（勝ちアド）はあるが、事前組み合わせCandidateAdAssets（組み合わせ結果）がない
          if (!!result.champion_ad && result.candidate_ads.length === 0) {
            powerwordState.setLoadingOff()
            errorMessage.value = '配信中RSAAdの取得に失敗しました。<br/>事前組み合わせアセットが存在しません。'
            return
          }
          powerwordState.setLoadingOff()
          errorMessage.value = '直近の配信実績がないため組み合わせをスキップしました。'
          return
        })
      })

      Promise.all([kip, bangwp]).then(() => {
        // リアルタイム生成のキーワード選択を更新
        llmAssetGeneratorComponent.value.resetInputs(
          highKeywordsImp.value,
          ngWords.value,
          bannedAssets.value,
          championRSAAd.value)

        const champAd = championRSAAd.value
        if (!champAd) { return }

        // 配信中RSAAssetのNGワード表示
        reCheckNGWordsForChampionAd()

        const rsaAssets = champAd.title_assets.concat(champAd.description_assets)
        // 配信中RSAAssetの評価
        rsaAdRepository.estimateRSAAsset({
          token: auth.token,
          media_id: adgroup.media_id,
          account_id: adgroup.account_id,
          campaign_id: adgroup.campaign_id,
          adgroup_id: adgroup.adgroup_id,
          rsaAssets,
        }).then((r) => {
          rsaAdScore.value = r.rsa_score
          rsaAdStrength.value = r.ad_strength
        }).catch((e) => {
          errorMessage.value = e
        })
        // CandidateAssetsの評価
        estimateCandidateAssets()
      })
    }

    const createEmptyAsset = (account: Account, adgroup: AdGroup, assetType: ASSET_TYPE) => {
      return {
        generator_asset_id: null,
        media_id: account.media_id,
        campaign_id: adgroup.campaign_id,
        adgroup_id: adgroup.adgroup_id,
        keyword_id: null,
        keyword: null,
        asset_type: assetType,
        asset_text: '',
        generated_date: null,
        asset_text_default: '',
        asset_text_ng_tagged: '',
        is_editable: true,
        pinned_field: null,
        ng_check_types: new Set(),
      }
    }

    const removeEmptyAsset = (cas: CandidateAsset[]) => {
      return cas.filter((ca) => ca.asset_text.length > 0)
    }

    const estimateCandidateAssets = () => {
      const adgroup = powerwordState.selectedAdGroup
      const titleAssets = removeEmptyAsset(caTitles.value)
      const descriptionAssets = removeEmptyAsset(caDescriptions.value)
      if (!adgroup ||
          titleAssets.length <= 0 ||
          descriptionAssets.length <= 0) { return }
      powerwordState.setLoadingOn()
      rsaAdRepository.estimateRSAAsset({
        token: auth.token,
        media_id: adgroup.media_id,
        account_id: adgroup.account_id,
        campaign_id: adgroup.campaign_id,
        adgroup_id: adgroup.adgroup_id,
        rsaAssets: (titleAssets.concat(descriptionAssets) as CandidateAsset[]).map((ca) => {
          return {
            word: ca.asset_text,
            asset_type: ca.asset_type,
            pinned_field: ca.pinned_field,
            generator_asset_id: ca.generator_asset_id,
            keyword_id: ca.keyword_id,
            keyword: ca.keyword,
          }
        }) as RSAAsset[],
      }).then((r) => {
        powerwordState.setLoadingOff()
        caScore.value = r.rsa_score
        caAdStrength.value = r.ad_strength
      }).catch((e) => {
        errorMessage.value = e
      })
    }

    const canCombineLLMAssets = computed < boolean > (() => {
      const titleAssets = removeEmptyAsset(caTitles.value).filter((ca) => ca.is_editable)
      const descriptionAssets = removeEmptyAsset(caDescriptions.value).filter((ca) => ca.is_editable)

      const titleLLMAssets = llmCandidateAssets.value.filter((llmca) => llmca.asset_type == ASSET_TYPE.TITLE && llmca.is_editable)
      const descriptionLLMAssets = llmCandidateAssets.value.filter((llmca) => llmca.asset_type == ASSET_TYPE.DESCRIPTION && llmca.is_editable)

      return (titleAssets.length > 0 && titleLLMAssets.length > 0) ||
          (descriptionAssets.length > 0 && descriptionLLMAssets.length > 0)
    })

    const combineLLMAssets = () => {
      const account = powerwordState.selectedAccount
      const adgroup = powerwordState.selectedAdGroup
      const titleLLMAssets = llmCandidateAssets.value.filter((llmca) => llmca.asset_type == ASSET_TYPE.TITLE && llmca.is_editable)
      const descriptionLLMAssets = llmCandidateAssets.value.filter((llmca) => llmca.asset_type == ASSET_TYPE.DESCRIPTION && llmca.is_editable)
      let titleAssets = removeEmptyAsset(caTitles.value)
      let descriptionAssets = removeEmptyAsset(caDescriptions.value)

      // TODO ここのロジックややこしいからなんとかわかりやすくしたい
      // 組み合わせ前にチェックが入ってなかったassetを保持
      const uncheckedTitles = titleAssets.flatMap((tca) => !tca.is_editable ? tca.asset_text : [])
      const uncheckedDescriptions = descriptionAssets.flatMap((dca) => !dca.is_editable ? dca.asset_text : [])

      // タイトル生成アセットが一つでも含まれていたら、編集チェックのあるCandidateAssetを残存組（fixed_assets）から除外する
      if (titleLLMAssets.length > 0) {
        titleAssets = titleAssets.filter((ca) => !ca.is_editable)
      }
      // 説明生成アセットが一つでも含まれていたら、編集チェックのあるCandidateAssetを残存組（fixed_assets）から除外する
      if (descriptionLLMAssets.length > 0) {
        descriptionAssets = descriptionAssets.filter((ca) => !ca.is_editable)
      }
      if (!account ||
          !adgroup ||
          (titleAssets.length <= 0 && titleLLMAssets.length <= 0) ||
          (descriptionAssets.length <= 0 && descriptionLLMAssets.length <= 0)) { return }
      powerwordState.setLoadingOn()
      rsaAdRepository.combineRSAAsset({
        token: auth.token,
        media_id: adgroup.media_id,
        account_id: adgroup.account_id,
        campaign_id: adgroup.campaign_id,
        adgroup_id: adgroup.adgroup_id,
        rsaAssets: (titleAssets.concat(descriptionAssets) as CandidateAsset[]).map((ca) => {
          return {
            word: ca.asset_text,
            asset_type: ca.asset_type,
            pinned_field: ca.pinned_field,
            generator_asset_id: ca.generator_asset_id,
            keyword_id: ca.keyword_id,
            keyword: ca.keyword,
          }
        }) as RSAAsset[],
        llmCandidateAssets: titleLLMAssets.concat(descriptionLLMAssets),
      }).then((r) => {
        caTitles.value = []
        caDescriptions.value = []
        caScore.value = r.rsa_score
        caAdStrength.value = r.ad_strength

        // Candidate Asset
        const cas: CandidateAsset[] = r.candidate_assets.map((ca) => {
          let isEditable = false
          if (
              (ca.asset_type == ASSET_TYPE.TITLE && !uncheckedTitles.includes(ca.asset_text)) ||
              (ca.asset_type == ASSET_TYPE.DESCRIPTION && !uncheckedDescriptions.includes(ca.asset_text))
          ) {
            isEditable = true
          }

          return {
            generator_asset_id: ca.generator_asset_id,
            media_id: ca.media_id,
            campaign_id: ca.campaign_id,
            adgroup_id: ca.adgroup_id,
            keyword_id: ca.keyword_id,
            keyword: ca.keyword,
            asset_type: ca.asset_type,
            asset_text: ca.asset_text,
            generated_date: ca.generated_date,
            asset_text_default: ca.asset_text,
            asset_text_ng_tagged: ca.asset_text,
            is_editable: isEditable,
            pinned_field: ca.pinned_field,
            ng_check_types: new Set(),
          }
        }) as CandidateAsset[]
        // NgWord再適用
        const taggedCas: CandidateAsset[] = reCheckNGWords(cas)

        setCandidateAssetsOnLeftSide(taggedCas, account, adgroup)

        errorMessage.value = null
        powerwordState.setLoadingOff()
      }).catch((e) => {
        errorMessage.value = e
        powerwordState.setLoadingOff()
      })
    }

    const onClickOpenHistory = (withAdgId: boolean = false) => {
      const account = powerwordState.selectedAccount
      const adgroup = powerwordState.selectedAdGroup
      if (!account || !adgroup) { return }
      const optAdgroup = withAdgId ? adgroup : null
      produceRSAHistoryModal.value.reloadHistory(account, optAdgroup)
      powerwordState.setProduceRSAHistoryModal(true)
    }

    const onClickDeleteTitle = (index: number) => {
      const account = powerwordState.selectedAccount
      const adgroup = powerwordState.selectedAdGroup
      if (!account || !adgroup) { return }
      caTitles.value.splice(index, 1,
          createEmptyAsset(account, adgroup, ASSET_TYPE.TITLE) as CandidateAsset)
    }

    const onClickDeleteDescription = (index: number) => {
      const account = powerwordState.selectedAccount
      const adgroup = powerwordState.selectedAdGroup
      if (!account || !adgroup) { return }
      caDescriptions.value.splice(index, 1,
          createEmptyAsset(account, adgroup, ASSET_TYPE.DESCRIPTION) as CandidateAsset)
    }

    const onClickClipBoardCandidateAssets = () => {
      if (navigator.clipboard && !!championRSAAd.value) {
        powerwordState.setLoadingOn()
        const candidateAssets: string[] = caTitles.value.concat(caDescriptions.value)
            .map((ca: CandidateAsset) => {
              const pinnedFiledOnCsv = ca.pinned_field ? pinnedFieldFormat(ca.pinned_field) + '番目' : '-'
              return `${ca.asset_text}\t${pinnedFiledOnCsv}`
            })
        const allAssets: string[] = [caScore.value ? caScore.value.toString() : '',
          caAdStrength.value ? adStrengthFormat(caAdStrength.value) : ''].concat(candidateAssets)
        const texts = allAssets.join('\n')
        return navigator.clipboard.writeText(texts).then(() => {
          powerwordState.setLoadingOff()
        })
      }
    }

    const onClickClipBoardChampionRSAAd = () => {
      if (navigator.clipboard && !!championRSAAd.value) {
        powerwordState.setLoadingOn()
        const titleAssets = championRSAAd.value.title_assets.map((cra: RSAAssetImp) => {
          const pinnedFiledOnCsv = cra.pinned_field ? pinnedFieldFormat(cra.pinned_field) + '番目' : '-'
          return `${cra.imp}\t${cra.word}\t${pinnedFiledOnCsv}`
        })
        while (titleAssets.length < TITLE_UPPER_LIMIT) {
          titleAssets.push('')
        }
        const descriptionAssets = championRSAAd.value.description_assets.map((cra: RSAAssetImp) => {
          const pinnedFiledOnCsv = cra.pinned_field ? pinnedFieldFormat(cra.pinned_field) + '番目' : '-'
          return `${cra.imp}\t${cra.word}\t${pinnedFiledOnCsv}`
        })
        while (descriptionAssets.length < DESCRIPTION_UPPER_LIMIT) {
          descriptionAssets.push('')
        }
        const rsaAdAssets: string[] = titleAssets.concat(descriptionAssets)
        const allAssets: string[] = [
          rsaAdScore.value ? `\t${rsaAdScore.value.toString()}` : '',
          '', // 余白改行
        ].concat(rsaAdAssets)
        const texts = allAssets.join('\n')
        return navigator.clipboard.writeText(texts).then(() => {
          powerwordState.setLoadingOff()
        })
      }
    }

    const onChangeCheckbox = (ca: CandidateAsset) => {
      // 編集チェックをオフにしたら文言をdefaultに戻す
      if (!ca.is_editable) {
        ca.asset_text = ca.asset_text_default
      }
    }

    const onChangeInput = (ca: CandidateAsset) => {
      // 入力された改行を削除
      ca.asset_text = ca.asset_text.replace(/\n+$/g, '')
    }

    const isScoreHigher = computed < boolean > (() => {
      return !!caScore.value && !!rsaAdScore.value && caScore.value > rsaAdScore.value
    })

    const postRSAProduct = () => {
      const client = powerwordState.selectedClientCompany
      const account = powerwordState.selectedAccount
      const adgroup = powerwordState.selectedAdGroup
      const titleAssets = removeEmptyAsset(caTitles.value)
      const descriptionAssets = removeEmptyAsset(caDescriptions.value)
      if (!client || !account || !adgroup ||
          titleAssets.length <= 0 || descriptionAssets.length <= 0) { return }
      powerwordState.setLoadingOn()
      rsaAdRepository.postRSAAsset({
        token: auth.token,
        user: auth.employeeNumber,
        cm_client_company_id: client.cm_client_company_id,
        media_id: adgroup.media_id,
        account_id: account.account_id,
        account_name: account.account_name,
        campaign_id: adgroup.campaign_id,
        campaign_name: adgroup.campaign_name,
        adgroup_id: adgroup.adgroup_id,
        adgroup_name: adgroup.adgroup_name,
        pre_combined_rsa_id: caPreCombinedRSAId.value,
        caAssets: titleAssets.concat(descriptionAssets) as CandidateAsset[],
      }).then((_) => {
        powerwordState.setLoadingOff()
      }).catch((e) => {
        errorMessage.value = e
      })
    }

    // NgWord再適用
    // webでNGWordを適用するのは、以下のケース
    // ①PreCombinedAssetがない（OnDemandAssetがある）
    // ②履歴編集（editEstimateHistory）
    // ③繰り返し（reload）
    const reCheckNGWords = (cas: CandidateAsset[]): CandidateAsset[] => {
      const ngws = ngWords.value
      let ngTaggedCandidateAssets: CandidateAsset[] = cas
      ngws.forEach((ngw) => {
        ngTaggedCandidateAssets = cas.map((cad) => {
          return checkNGWord(ngw, cad)
        })
      })

      return ngTaggedCandidateAssets
    }

    const reCheckNGWordsForChampionAd = () => {
      const champAd = championRSAAd.value
      if (!champAd) { return }

      const ngws = ngWords.value
      ngws.forEach((ngw) => {
        champAd.title_assets = champAd.title_assets.map((rat) => {
          return checkNGWordForRSAAsset(ngw, rat)
        })
        champAd.description_assets = champAd.description_assets.map((rad) => {
          return checkNGWordForRSAAsset(ngw, rad)
        })
      })
      championRSAAd.value = champAd
    }

    // 下位キーワード表示・非表示
    const toggleLowKeywordsVisibility = () => {
      isLowKeywordsVisible.value = !isLowKeywordsVisible.value
    }

    // NGワード表示・非表示
    const toggleNGWordsVisibility = () => {
      isNGWordsVisible.value = !isNGWordsVisible.value
    }

    // RSAProductの再編集
    const editRSAProduct = (rsaProduct: RSAProduct) => {
      const account = powerwordState.selectedAccount
      const adgroup = powerwordState.selectedAdGroup
      if (!account || !adgroup) { return }
      caTitles.value = []
      caDescriptions.value = []
      caScore.value = rsaProduct.rsa_product_score
      caAdStrength.value = rsaProduct.ad_strength

      // PreCombinedRSAId
      caPreCombinedRSAId.value = rsaProduct.pre_combined_rsa_id
      // Candidate Asset
      const cas: CandidateAsset[] = rsaProduct.assets.map((rpa) => {
        return {
          generator_asset_id: rpa.generator_asset_id,
          media_id: adgroup.media_id,
          campaign_id: adgroup.campaign_id,
          adgroup_id: adgroup.adgroup_id,
          keyword_id: rpa.keyword_id,
          keyword: rpa.keyword,
          asset_type: rpa.asset_type,
          asset_text: rpa.asset_text,
          generated_date: rpa.generated_date,
          asset_text_default: rpa.asset_text,
          asset_text_ng_tagged: rpa.asset_text,
          is_editable: !!rpa.generator_asset_id,
          pinned_field: rpa.pinned_field,
          ng_check_types: new Set(),
        }
      }) as CandidateAsset[]

      const taggedCas = reCheckNGWords(cas)
      setCandidateAssetsOnLeftSide(taggedCas, account, adgroup)
    }

    // PreCombinedAdAssetを切り替え
    const switchPreCombinedAdAsset = (selectionCount: number, reEstimate = true) => {
      const index = selectionCount - 1
      const account = powerwordState.selectedAccount
      const adgroup = powerwordState.selectedAdGroup
      if (!account || !adgroup || !(index in preCombinedCandidateAds.value)) { return }
      caTitles.value = []
      caDescriptions.value = []
      caScore.value = null
      caAdStrength.value = null

      const selectedPreCombinedRSAAd = preCombinedCandidateAds.value[index]
      // PreCombinedRSAId
      caPreCombinedRSAId.value = selectedPreCombinedRSAAd.pre_combined_rsa_id
      // Candidate Asset
      const cas: CandidateAsset[] = selectedPreCombinedRSAAd.assets

      // NgWord再適用
      // Note:// 事前組み合わせアセットはバッチ側でNGWord が反映されているためここでは何もしない
      // const ngTaggedAssets = reCheckNGWords(cas)
      // CandidateAssetsに対してBannedAssetを適用
      const unbannedAssets =  removeBannedAssetFromCandidates(cas)
      setCandidateAssetsOnLeftSide(unbannedAssets, account, adgroup)

      selectedPriority.value = selectionCount

      if (reEstimate) {
        estimateCandidateAssets()
      }
    }

      // filter_logicでボタンの色変え
    const filterLogicColor = (selectionCount: number) => {
      const index = selectionCount - 1
      if (!(index in preCombinedCandidateAds.value)) { return }
      const filterLogic = preCombinedCandidateAds.value[index].filter_logic
      const generationStrategy = preCombinedCandidateAds.value[index].ad_candidate_generation_strategy
      if (filterLogic == PRE_COMBINED_RSA_FILTER_LOGIC.QS_FLOW) {
        if (generationStrategy == PRE_COMBINED_RSA_GENERATION_STRATEGY.CATEGORY) {
          return 'qs_flow_category'
        } else {
          return 'qs_flow_random'
        }
      }
      return 'legacy'
    }

    const setCandidateAssetsOnLeftSide = (cas: CandidateAsset[], account: Account, adgroup: AdGroup) => {
      caTitles.value =
          cas.filter((ca) => ca.asset_type === ASSET_TYPE.TITLE)
      while (caTitles.value.length < TITLE_UPPER_LIMIT) {
        caTitles.value.push(
            createEmptyAsset(account, adgroup, ASSET_TYPE.TITLE) as CandidateAsset)
      }
      caDescriptions.value =
          cas.filter((ca) => ca.asset_type === ASSET_TYPE.DESCRIPTION)
      while (caDescriptions.value.length < DESCRIPTION_UPPER_LIMIT) {
        caDescriptions.value.push(
            createEmptyAsset(account, adgroup, ASSET_TYPE.DESCRIPTION) as CandidateAsset)
      }
    }

    const kwBgColor = (kw: KeywordImpConv) => {
      if (kw.ad_relevance == AD_RELEVANCE.BELOW || kw.expected_ctr == EXPECTED_CTR.BELOW) {
        return "below"
      } else if (kw.ad_relevance == AD_RELEVANCE.AVERAGE || kw.expected_ctr == EXPECTED_CTR.AVERAGE) {
        return 'average'
      } else {
        return 'above'
      }
    }

    // 子（LLMAssetGenerator）で更新された値を親で更新
    const updateLLMAssets = (assets: CandidateAsset[]) => {
      llmCandidateAssets.value = assets
    }

    // Banned Asset
    const onClickBanButton = () => {
      isShowBanButton.value = !isShowBanButton.value
    }

    const isDisableOpenBanAsset = (ca: CandidateAsset) => {
      return !selectedClient.value || !ca || !ca.generator_asset_id || ca.asset_text_default.length <= 0
    }

    const onClickOpenBanAsset = (candidateAsset: CandidateAsset) => {
      bannedAssetModal.value.reload(candidateAsset)
      powerwordState.setBannedAssetModal(true)
    }

    // caに対してbanned_assetsを反映させる
    const removeBannedAssetFromCandidates = (cas: CandidateAsset[]): CandidateAsset[] => {
      const bas = bannedAssets.value
      return cas.flatMap((ca) => {
        if (bas.some((ba) => isBannedAsset(ba, ca))) {
          return []
        }
        else { return ca }
      }) as CandidateAsset[]
    }

    // 暫定処理 モーダルからbanned_assets登録直後に、candidate_assets（画面左）から弾く処理
    // 理想はapiからbanned_assets再取得したい
    const afterBanAsset = (bannedCandidateAsset: CandidateAsset) => {
      const account = powerwordState.selectedAccount
      const adgroup = powerwordState.selectedAdGroup
      if (!account || !adgroup) { return }

      const ba: BannedAsset = {
        banned_asset_id: null,
        cm_client_company_id: account.cm_client_company_id,
        asset_text: bannedCandidateAsset.asset_text_default,
        reason: '',
        generator_asset_id: bannedCandidateAsset.generator_asset_id,
        created_user: '',
        created_at: '',
        updated_user: null,
        updated_at: null,
      }

      const cas = caTitles.value.concat(caDescriptions.value)
      const unbannedAssets = cas.flatMap((ca) => {
        if (isBannedAsset(ba, ca)) {
          return []
        } else {
          return ca
        }
      }) as CandidateAsset[]
      setCandidateAssetsOnLeftSide(unbannedAssets, account, adgroup)
    }

    return {
      textFullLength,
      caTitles,
      caDescriptions,
      selectedClient,
      selectedAccount,
      accounts,
      selectedAccountValue,
      selectAccountOptions,
      onSelectAccount,
      adGroups,
      selectedAdGroup,
      selectedAdGroupValue,
      selectAdGroupOptions,
      onSelectAdGroup,
      onClickDeleteTitle,
      onClickDeleteDescription,
      onClickClipBoardCandidateAssets,
      onClickClipBoardChampionRSAAd,
      onChangeCheckbox,
      onChangeInput,
      estimateCandidateAssets,
      canCombineLLMAssets,
      combineLLMAssets,
      championRSAAd,
      errorMessage,
      caScore,
      rsaAdStrength,
      rsaAdScore,
      caAdStrength,
      isScoreHigher,
      onClickOpenHistory,
      scoreFormat,
      adStrengthFormat,
      pinnedFieldFormat,
      ngCheckTypeFormat,
      ngCheckTypeFormatForRSA,
      postRSAProduct,
      highKeywordsImp,
      lowKeywordsImp,
      isLowKeywordsVisible,
      toggleLowKeywordsVisibility,
      isNGWordsVisible,
      toggleNGWordsVisibility,
      pinnedFieldHeadlines,
      pinnedFieldDescriptions,
      produceRSAHistoryModal,
      editRSAProduct,
      preCombinedCandidateAds,
      switchPreCombinedAdAsset,
      filterLogicColor,
      selectedPriority,
      SELECTION_COUNT,
      llmAssetGeneratorComponent,
      kwBgColor,
      updateLLMAssets,
      ngWords,
      bannedAssetModal,
      onClickOpenBanAsset,
      afterBanAsset,
      isDisableOpenBanAsset,
      onClickBanButton,
      isShowBanButton,
    }
  },
})

export default ProduceRSAComponent
</script>
