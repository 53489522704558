export interface CmClientCompany {
  cm_client_company_id: string,
  cm_client_company_name: string,
  account_ids_str: string | null,
}

export interface CmClientCompanyOption {
  value: CmClientCompany
  label: string
  track_by: string
}

export const enum MEDIA_ID {
  YAHOO = 1,
  GOOGLE = 2,
}

export function getMediaId(raw: number): MEDIA_ID | null {
  if (raw === 1) {
    return MEDIA_ID.YAHOO
  } else if (raw === 2) {
    return MEDIA_ID.GOOGLE
  }
  return null
}

export interface Account {
  cm_client_company_id: string,
  media_id: MEDIA_ID,
  account_id: string,
  account_name: string,
}

export interface AccountOption {
  value: Account
  label: string
  track_by: string
}

export interface AdGroup {
  media_id: MEDIA_ID,
  account_id: string,
  campaign_id: string,
  campaign_name: string,
  adgroup_id: string,
  adgroup_name: string,
  is_valid: boolean,
}

export interface AdGroupOption {
  value: AdGroup
  label: string // multi-selectのlabelで使用
  track_by: string // multi-selectのtrackingで使用
  disabled: boolean;
}

export interface Money {
  amount: number,
  currency: string,
}

export interface RequestKeywordId {
  media_id: MEDIA_ID,
  account_id: string,
  campaign_id: string,
  adgroup_id: string,
  keyword_id: string,
}

export interface Keyword {
  media_id: MEDIA_ID,
  account_id: string,
  campaign_id: string,
  campaign_name: string | null,
  adgroup_id: string,
  adgroup_name: string | null,
  keyword_id: string,
  keyword: string,
  key: string,  // account_id + ':' + media_id + ':' + campaign_id + ':' + adgroup_id + ':' + keyword_id
  match_type: MATCH_TYPE,
  daily_budget: Money | null,
  track_id: string, // multi-selectのtrackingで使用
}

export interface KeywordImpConv extends Keyword {
  imp: number,
  conv: number,
  expected_ctr: EXPECTED_CTR,
  ad_relevance: AD_RELEVANCE,
}

export const enum EXPECTED_CTR {
  AVERAGE = 'average',
  ABOVE = 'above',
  BELOW = 'below',
}

export const enum AD_RELEVANCE {
  AVERAGE = 'average',
  ABOVE = 'above',
  BELOW = 'below',
}

export interface KeywordImpConvOption {
  value: KeywordImpConv
  label: string
  track_by: string
}

export const enum MATCH_TYPE {
  EXACT = 'Exact',
  BROAD = 'Broad',
  PHRASE = 'Phrase',
  UNKNOWN = 'Unknown',
}

export function getMatchType(raw: string | null): MATCH_TYPE {
  if (!raw) {
    return MATCH_TYPE.UNKNOWN
  }
  const lower = raw.toLowerCase()
  if (lower === 'exact') {
    return MATCH_TYPE.EXACT
  } else if (lower === 'broad') {
    return MATCH_TYPE.BROAD
  } else if (lower === 'phrase') {
    return MATCH_TYPE.PHRASE
  } else {
    return MATCH_TYPE.UNKNOWN
  }
}

export interface ChampionRSAAd {
  media_id: number,
  account_id: string,
  campaign_id: string,
  adgroup_id: string,
  ad_id: string,
  ad_type: string | null,
  asset_id: string | null,
  final_url: string | null,
  path_1: string | null,
  path_2: string | null,
  title_assets: RSAAssetImp[],
  description_assets: RSAAssetImp[],
}

export interface RSAAsset {
  word: string,
  asset_type: ASSET_TYPE,
  pinned_field: ASSET_PINNED_FILED | null,
  generator_asset_id: number | null,
  keyword_id: string | null,
  keyword: string | null,
}

export interface RSAAssetImp extends RSAAsset {
  imp: number,
  asset_text_ng_tagged: string,
  ng_check_types: Set <NG_TYPE>,
}

export const enum ASSET_PINNED_FILED {
  HEADLINE_1 = 'HEADLINE_1',
  HEADLINE_2 = 'HEADLINE_2',
  HEADLINE_3 = 'HEADLINE_3',
  DESCRIPTION_1 = 'DESCRIPTION_1',
  DESCRIPTION_2 = 'DESCRIPTION_2',
}

export const enum PRE_COMBINED_RSA_FILTER_LOGIC {
  QS_FLOW = 'QS_FLOW',
  LEGACY = 'LEGACY',
  UNKNOWN = 'UNKNOWN',
}

export const enum PRE_COMBINED_RSA_GENERATION_STRATEGY {
  RANDOM = 'RANDOM',
  CATEGORY = 'CATEGORY',
  UNKNOWN = 'UNKNOWN',
}

export function getPinnedField(rawNum: number | null, assetType: ASSET_TYPE): ASSET_PINNED_FILED | null {
  if (assetType === ASSET_TYPE.TITLE) {
    switch (rawNum) {
      case 1:
        return ASSET_PINNED_FILED.HEADLINE_1
      case 2:
        return ASSET_PINNED_FILED.HEADLINE_2
      case 3:
        return ASSET_PINNED_FILED.HEADLINE_3
    }
  } else if (assetType === ASSET_TYPE.DESCRIPTION) {
    switch (rawNum) {
      case 1:
        return ASSET_PINNED_FILED.DESCRIPTION_1
      case 2:
        return ASSET_PINNED_FILED.DESCRIPTION_2
    }
  }
  return null
}

export interface TitleDescription {
  title_1: string | null,
  title_2: string | null,
  title_3: string | null,
  description_1: string | null,
  description_2: string | null,
}

// TODO: サーバ側から修正後に消す
export interface PowerWordLabel {
  pw_label_id: number | null,
  cm_client_company_id: string,
  name: string,
}

export const enum KEYWORD_TYPE {
  ALL = -1, // 検索絞り込み用
  BY_CLIENT = 0,
  BY_KEYWORD = 1,
}


export interface CandidateAsset {
  generator_asset_id: number | null,
  media_id: MEDIA_ID | null,
  campaign_id: string,
  adgroup_id: string,
  keyword_id: string | null,
  keyword: string | null,
  match_type: MATCH_TYPE | null,
  asset_type: ASSET_TYPE,
  asset_text: string,
  generated_date: string | null,
  asset_text_default: string,
  asset_text_ng_tagged: string,
  is_editable: boolean,
  pinned_field: ASSET_PINNED_FILED | null,
  ng_check_types: Set <NG_TYPE>,
}

export interface PowerWordAsset {
  pw_asset_id: number,
  cm_client_company_id: string,
  account_id: string | null,
  media_id: MEDIA_ID | null,
  campaign_id: string | null,
  adgroup_id: string | null,
  keyword_id: string | null,
  keyword: string | null,
  word: string,
  asset_score: number | null,
  pw_asset_type: ASSET_TYPE,
  pw_asset_kw_type: KEYWORD_TYPE,
  pw_labels: PowerWordLabel[], // TODO: サーバ側から修正後に消す
  is_deleted: boolean,
  created_user: string,
  created_at: string,
  updated_user: string,
  updated_at: string,
  count_powerwords: number,
}

export const enum ASSET_TYPE {
  ALL = -1, // 検索絞り込み用
  TITLE = 0,
  DESCRIPTION = 1,
}

export const enum AD_STRENGTH {
  INCOMPATIBLE = 0,
  POOR = 1,
  AVERAGE = 2,
  GOOD = 3,
  EXCELLENT = 4,
}

export interface NGWord {
  ng_word_id: number,
  cm_client_company_id: string,
  media_id: MEDIA_ID | null
  account_id: string | null
  campaign_id: string | null
  adgroup_id: string | null
  ng_word: string,
  replaced_word: string | null,
  reason: string | null,
  ng_type: NG_TYPE,
  aitd_upload_id: string | null
  inspector_rule_id: string | null
  inspector_rule_source: string | null
  created_user: string | null,
  created_at: string | null,
  updated_user: string | null,
  updated_at: string | null,
}

export enum NG_TYPE {
  ABSOLUTELY_NG = 1,
  AVAILABLE_REPLACE = 2,
}

export interface BulkEstimateRSAAdAsset {
  row_num: number,
  media_id: MEDIA_ID,
  account_id: string,
  campaign_id: string,
  adgroup_id: string,
  ad_number: string,
  asset_type: ASSET_TYPE,
  pinned_field: ASSET_PINNED_FILED | null,
  word: string,
  is_editable: boolean,
  score: number | null,
  ad_strength: AD_STRENGTH | null,
}

export interface BulkDownloadRSAAd {
  account_id: string,
  media_id: MEDIA_ID,
  campaign_id: string,
  adgroup_id: string,
  ad_id: string | null,
  word: string | null,
  asset_type: ASSET_TYPE | null,
  pinned_field: ASSET_PINNED_FILED | null,
  ad_strength: AD_STRENGTH | null,
  score: number | null,
}

export interface BulkDownloadPreCombinedAsset {
  account_id: string,
  media_id: MEDIA_ID,
  campaign_id: string,
  adgroup_id: string,
  pre_combined_rsa_id: string,
  selection_priority: number,
  is_new: Boolean,
  candidate_word: string | null,
  candidate_asset_type: ASSET_TYPE | null,
  candidate_pinned_field: ASSET_PINNED_FILED | null,
  candidate_ad_strength: AD_STRENGTH | null,
  candidate_score: number | null,
  champion_ad_id: string | null,
  champion_word: string | null,
  champion_asset_type: ASSET_TYPE | null,
  champion_pinned_field: ASSET_PINNED_FILED | null,
  champion_score: number | null,
  champion_imp: number | null,
}

export interface RSAProduct {
  rsa_product_id: number,
  cm_client_company_id: string,
  media_id: MEDIA_ID,
  account_id: string,
  account_name: string,
  campaign_id: string,
  campaign_name: string,
  adgroup_id: string,
  adgroup_name: string,
  rsa_product_score: number,
  ad_strength: AD_STRENGTH,
  pre_combined_rsa_id: string | null,
  created_user: string,
  created_at: string,
  updated_user: string,
  updated_at: string,
  assets: RSAProductAsset[],
}

export interface RSAProductAsset {
  rsa_product_id: number,
  generator_asset_id: number | null,
  generated_date: number | null,
  asset_type: ASSET_TYPE,
  asset_text: string,
  keyword_id: string | null,
  keyword: string | null,
  is_editable: boolean,
  pinned_field: ASSET_PINNED_FILED | null,
}

export interface PreCombinedCandidateAd {
  media_id: number
  campaign_id: string
  adgroup_id: string
  selection_priority: number
  pre_combined_rsa_id: string
  ad_candidate_generation_strategy: PRE_COMBINED_RSA_GENERATION_STRATEGY
  filter_logic: PRE_COMBINED_RSA_FILTER_LOGIC
  assets: CandidateAsset[]
}

export interface PreCombinedAds {
  champion_ad: ChampionRSAAd | null
  candidate_ads: PreCombinedCandidateAd[]
}

export interface LLMAsset {
  word: string,
  asset_type: ASSET_TYPE,
  keyword: KeywordImpConv,
}

export interface BannedAsset {
  banned_asset_id: number | null,
  cm_client_company_id: string,
  asset_text: string,
  reason: string,
  generator_asset_id: number | null,
  created_user: string,
  created_at: string,
  updated_user: string | null,
  updated_at: string | null,
}
